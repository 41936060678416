import React from "react"
import { useTranslation } from "react-i18next"
import SVG from "~/images/404.svg"

interface Props {}

const Error: React.FC<Props> = () => {
  const { t } = useTranslation()

  const style = `#page-404 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 80em;
        margin: 0 auto;
      }
    
      #page-404 .page-404__content {
        flex: 1;
        padding: 4rem 2rem;
        color: #808080;
      }
    
      #page-404 .page-404__content--graphic {
        display: none;
      }
    
      #page-404 .page-404__title {
        margin: 0 0 1rem;
        font-size: 2.5rem;
      }
    
      #page-404 .page-404__subtitle {
        margin: 0 0 1.5rem;
      }
    
      #page-404 .page-404__btn-home {
        display: inline-block;
        padding: 1rem 1.25rem;
        border-radius: 6px;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        background-color: #eb4e85;
        text-decoration: none;
        transition: all 0.2s;
      }
    
      #page-404 .page-404__btn-home:hover,
      #page-404 .page-404__btn-home:active {
        opacity: 0.85;
      }
    
      #page-404 .page-404__graphic {
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    
      @media (min-width: 48em) {
        #page-404 {
          flex-direction: row;
        }
        #page-404 .page-404__content {
          padding: 2rem 1rem;
        }
    
        #page-404 .page-404__content--graphic {
          width: 70%;
          display: block;
        }
    
        #page-404 .page-404__title {
          font-size: 3.25rem;
        }
      }`

  return (
    <>
      <div id="page-404">
        <div className="page-404__content">
          <h1 className="page-404__title">
            {t("templates.layout.error.Error404.Title")}
          </h1>
          <h2 className="page-404__subtitle">
            {t("templates.layout.error.Error404.Subtitle")}
          </h2>
          <a href="/" className="page-404__btn-home">
            ←&nbsp;{t("templates.layout.error.Error404.Home")}
          </a>
        </div>
        <div className="page-404__content page-404__content--graphic">
          <img className="page-404__graphic" src={`${SVG}`} alt="404 image" />
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: style,
        }}
      ></style>
    </>
  )
}

export default Error
